body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root{
  height: 100vh;
  width: 100vw;
}

body {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: url("https://images.unsplash.com/photo-1447433589675-4aaa569f3e05?ixlib=rb-0.3.5&s=4222852e25e0f57d9485f7889957e99a&auto=format&fit=crop&w=2000&q=80");
  background-size: cover;
  background: #ccc;
  background-position: 0 50%;
  background: #deb493;
}

.background:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0);
}

.outer-div,
.inner-div {
  height: 100vh;
  width: 100vw;
  margin: 0 auto;
  position: relative;
  overflow-y: auto;
}

.outer-div {
  perspective: 900px;
  perspective-origin: 50% calc(50% - 18em);
}

.inner-div {
  margin: 0 auto;
  border-radius: 5px;
  font-weight: 400;
  color: #071011;
  font-size: 1rem;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.8, -0.4, 0.2, 1.7);
  transform-style: preserve-3d;
}

.inner-div:hover .social-icon {
  opacity: 1;
  top: 0;
}

.outer-div:hover .inner-div {
  transform: rotateY(180deg);
}

.front,
.back {
  position: relative;
  top: 0;
  left: 0;
  backface-visibility: hidden;
}

.front {
  cursor: pointer;

  background: #e2efec;
  backface-visibility: hidden;
  border-radius: 5px;
  box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.front__bkg-photo {
  position: relative;
  height: 190px;
  width: 100%;
  background-color: #45a049;
  /* background: url("https://images.unsplash.com/photo-1511207538754-e8555f2bc187?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=88672068827eaeeab540f584b883cc66&auto=format&fit=crop&w=1164&q=80") no-repeat; */
  background-size: cover;
  backface-visibility: hidden;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.front__bkg-photo:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.front__face-photo {
  position: relative;
  top: -130px;
  height: 260px;
  width: 85%;
  margin: 0 auto;
  border-radius: 15%;
  border: 5px solid #fff;
 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.front__text_other{
  position: relative;
  top: -110px;
  margin: 0 auto;
  font-family: "Montserrat";
  font-size: 18px;
  backface-visibility: hidden;
}
.front__text {
  position: relative;
  top: -55px;
  margin: 0 auto;
  font-family: "Montserrat";
  font-size: 18px;
  backface-visibility: hidden;
}

.front__text_desc {
  position: relative;
  top: -55px;
  margin: 0 auto;

  font-family: "Montserrat";
  font-size: 18px;
  backface-visibility: hidden;
}

.front__text_desc>p {
  padding: 8px;
}

.front__description {
  position: relative;
  top: -55px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 24px;
  font-family: "Montserrat";
  font-size: 18px;
  backface-visibility: hidden;
}


.front__text-header {
  font-weight: 700;
  font-family: "Oswald";
  text-transform: uppercase;
  font-size: 20px;
}
.front__text-header-other {
  font-weight: 700;
  font-family: "Oswald";
  text-transform: uppercase;
  font-size: 20px;
  padding: 4px;
  margin: 4px;
}
.front__text-para-other{
  padding: 4px;
  margin: 4px;
}



.front__text-para_desc>p {
  padding: 8px;
}

.front__text-para_desc {
  padding: 8px;
  margin: 0px;
  position: relative;
  top: -5px;
  color: #000;
  font-size: 14px;
  letter-spacing: 0.4px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.front-icons {
  position: relative;
  top: 0;
  font-size: 14px;
  margin-right: 6px;
  color: gray;
}

.front__text-hover {
  position: relative;
  top: 10px;
  font-size: 10px;
  color: tomato;
  backface-visibility: hidden;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  border: 2px solid tomato;
  padding: 8px 15px;
  border-radius: 30px;
  background: tomato;
  color: #fff;
}

.back {
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #071011;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.social-media-wrapper {
  font-size: 36px;
}

.social-media-wrapper .social-icon {
  position: relative;
  top: 20px;
  margin-left: 5px;
  margin-right: 5px;
  opacity: 0;
  color: #fff;
  transition: all 0.4s cubic-bezier(0.3, 0.7, 0.1, 1.9);
}

.social-media-wrapper .social-icon:nth-child(1) {
  transition-delay: 0.6s;
}

.social-media-wrapper .social-icon:nth-child(2) {
  transition-delay: 0.7s;
}

.social-media-wrapper .social-icon:nth-child(3) {
  transition-delay: 0.8s;
}

.social-media-wrapper .social-icon:nth-child(4) {
  transition-delay: 0.9s;
}

.fab {
  position: relative;
  top: 0;
  left: 0;
  transition: all 200ms ease-in-out;
}

.fab:hover {
  top: -5px;
}



.profile-container {
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
  padding: 0;
  font-family: Arial, sans-serif;
}

.profile-header {
  position: relative;
  width: 100%;
  height: 200px;
}

.profile-cover-photo {
  width: 100%;
  height: 150px;
  background-color: #ddd;
  /* Placeholder color */
  background-size: cover;
  background-position: center;
}

.profile-photo {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid white;
  background-color: #bbb;
  /* Placeholder color */
  background-size: cover;
  background-position: center;
}

.profile-info {
  text-align: center;
  padding: 20px;
}

.profile-name {
  margin: 10px 0;
  font-size: 24px;
}

.profile-role {
  margin: 5px 0;
  color: #666;
}

.profile-location {
  margin: 5px 0;
  color: #666;
}

.location-icon {
  margin-right: 5px;
}

.profile-social {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.social-icon {
  font-size: 24px;
  color: #333;
  margin: 0 10px;
  text-decoration: none;
}

.profile-not-found {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
}



.call-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto 20px;
  padding: 10px;
  background-color: #3b5dbb;
  color: white;
  text-decoration: none;
  border-radius: 25px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.call-button:hover {
  background-color: #45a049;
}

.call-button i {
  margin-right: 10px;
}